import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/plugins/i18n";
import { watch } from "vue";

const routes = [
  // Redirect the root path
  {
    path: "/",
    redirect: "/wizard/select-management-way",
  },
  {
    path: "/auth/callback",
    name: "Callback Google",
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "../views/auth/googleCallback.vue"
      ),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // setting pages
  {
    path: "/setting",

    children: [
      {
        path: "my-profile",
        name: "My Profile",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/MyProfile.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "my-shop",
        name: "My Shop",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/MyShop.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "language",
        name: "Language Settings",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/languages.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },

      {
        path: "subscription",
        name: "Manage Your Subscription",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/SubscriptionDetails.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "points",
        name: "Purchase Credits",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/addCredits.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "subscribe",
        name: "Subscribe",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/subscribeNewUser.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "/cancel-payment",
        name: "Cancel Payment",
        redirect: "/setting/subscribe?fail=true",
      },
    ],
  },

  // --------------------------------------
  // Success Payment page
  {
    path: "/success-payment",
    name: "Success Payment",
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "@/views/settings/successPayment.vue"
      ),
    meta: { requiresAuth: true, layout: "dashboard" },
  },

  // --------------------------------------
  // history page
  {
    path: "/history",
    name: "My Products",
    component: () =>
      import(/* webpackChunkName: "wizard" */ "@/views/history/history.vue"),
    meta: { requiresAuth: true, layout: "dashboard" },
  },

  // --------------------------------------
  // Contact page
  {
    path: "/contact",
    name: "Contact us",
    component: () => import("@/views/contact/Contact.vue"),
    meta: { requiresAuth: true, layout: "dashboard" },
  },

  // --------------------------------------
  // wizard pages
  {
    path: "/wizard",
    children: [
      {
        path: "select-management-way",
        name: "AI-Powered Product Listing Management",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "../views/wizard/selectManagement.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "select-category",
        name: "Select Product Category",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "../views/wizard/images/selectCategory.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "search-barcode",
        name: "Scan or Enter Barcode",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/barcode/searchBarcode.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "products-store",
        name: "Import and Enhance Products from Your Store",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/store/productsStore.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "products-store/products",
        name: "Enhance Products from Your Store",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/store/enhanceProductStore.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "upload-images",
        name: "Provide Listapro with Data",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/images/UploadImage.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "listing",
        name: "Listing",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/wizard/listing.vue"),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "review",
        name: "Review Product",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/wizard/review.vue"),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "review/edit/:id",
        name: "Update Product Details",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/editProduct.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "review/preview/:id",
        name: "Product Preview",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/previewProduct.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
    ],
  },

  // --------------------------------------
  // login page
  {
    path: "/login",
    name: "Log In",
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/auth/login.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // register page
  {
    path: "/register",
    name: "Create an Account",
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/auth/register.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // shopify_auth page
  {
    path: "/shopify_auth",
    name: "shopify authentication",
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/auth/shopify_auth.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // shopify_callback page
  {
    path: "/shopify_callback",
    name: "authentication callback",
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "../views/auth/auth_callback.vue"
      ),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // forgot_password page
  {
    path: "/forgot_password",
    name: "Reset Password",
    component: () => import("@/views/auth/ForgetPassword.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // check_your_email page
  {
    path: "/check_your_email",
    name: "Check Your Email",
    component: () => import("@/views/auth/CheckYourEmail.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // Set New Password page
  {
    path: "/password/reset",
    name: "Create New Password",
    component: () => import("@/views/auth/SetNewPassword.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // Not Found page
  {
    path: "/:pathMatch(.*)*",
    name: "Not Found",
    component: () => import("@/views/NotFound.vue"),
    meta: { requiresAuth: true, layout: "default" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// before route enter any page based on authentication
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  const auth = localStorage.getItem("accessToken");

  // add name of page to the title of tap
  document.title = to.name
    ? `${i18n.global.t("Listapro")} - ${i18n.global.t(to.name)}`
    : i18n.global.t("Listapro");

  if (to.meta.requiresAuth && !auth) {
    // If the route requires authentication but user is not authenticated
    next({ name: "Log In" });
  } else if (
    auth &&
    (to.name === "Log In" || to.name === "Create an Account")
  ) {
    // Redirect authenticated users trying to access login or signup pages
    next({ name: "AI-Powered Product Listing Management" });
  } else {
    // to delete any localStorage for panel if i open any page without History.vue
    if (to.name !== "My Products") {
      localStorage.removeItem("panel-display");
      localStorage.removeItem("panel-display-state");
      localStorage.removeItem("panel-product-id");
      next();
    } else {
      next();
    }
  }
});

// Function to update the document title every language update
const updateTitle = (to) => {
  const title = to.name
    ? `${i18n.global.t("Listapro")} - ${i18n.global.t(to.name)}`
    : i18n.global.t("Listapro");
  document.title = title;
};

// Watch for language changes and update the title
watch(
  () => i18n.global.locale, // Watch for changes in the locale
  () => {
    updateTitle(router.currentRoute.value); // Update title based on the current route
  }
);
export default router;
