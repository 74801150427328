<template>
  <div class="dashboard-layout">
    <!-- the header navbar -->
    <Navbar />

    <!-- user data card info -->
    <UserData class="hide-in-small" />

    <!-- the list of product card -->
    <div class="list-of-product" v-if="isListOfProductsDisabled">
      <ListOfProduct />
    </div>

    <!-- dashboard content -->
    <div
      class="content"
      ref="scrollContainer"
      :class="isListOfProductsDisabled ? 'pb-10' : ''"
    >
      <!-- Credit Balance Depleted warning -->
      <div class="credits-warning" v-if="showCreditsWarning">
        <div>
          <div class="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM11.25 6.9375C11.25 6.83437 11.3344 6.75 11.4375 6.75H12.5625C12.6656 6.75 12.75 6.83437 12.75 6.9375V13.3125C12.75 13.4156 12.6656 13.5 12.5625 13.5H11.4375C11.3344 13.5 11.25 13.4156 11.25 13.3125V6.9375ZM12 17.25C11.7056 17.244 11.4253 17.1228 11.2192 16.9125C11.0132 16.7022 10.8977 16.4195 10.8977 16.125C10.8977 15.8305 11.0132 15.5478 11.2192 15.3375C11.4253 15.1272 11.7056 15.006 12 15C12.2944 15.006 12.5747 15.1272 12.7808 15.3375C12.9868 15.5478 13.1023 15.8305 13.1023 16.125C13.1023 16.4195 12.9868 16.7022 12.7808 16.9125C12.5747 17.1228 12.2944 17.244 12 17.25Z"
                fill="#B71C1C"
              />
            </svg>
          </div>
          <div class="description">
            <p class="title">{{ $t("Credit Balance Depleted") }}</p>
            <p class="desc">
              {{
                $t(
                  "Your credit balance is finished. To continue using premium features, please add new credits or purchase a credit package."
                )
              }}
            </p>
          </div>
        </div>
        <div class="btns">
          <button
            class="pay-now"
            @click="$router.push('/setting/subscription')"
          >
            {{ $t("Pay Now") }}
          </button>
          <button class="close" @click="closeWarning">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="20"
              height="20"
            >
              <path
                fill="#e53935"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- CONTENT SLOT -->
      <slot />
    </div>

    <!-- dialog for new-users .. for connect store -->
    <v-dialog v-model="newUserDialog">
      <v-card
        style="border-radius: 10px; padding: 20px 24px"
        class="dialog-expired"
      >
        <div class="d-flex align-center justify-between">
          <v-btn color="#F5222D" variant="tonal" @click="newUserDialog = false">
            <svg
              width="20"
              fill="#F5222D"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </v-btn>
        </div>
        <div class="lock">
          <img src="@/assets/images/wellcome.svg" alt="" />
        </div>

        <p class="title">
          {{ $t("Welcome to Sellenvo") }}
        </p>
        <p class="desc">
          {{
            $t(
              "It looks like you haven’t connected your store yet. To start publishing your products, you’ll need to link your store first"
            )
          }}
        </p>

        <div class="btns">
          <button @click="newUserDialog = false" class="export-products">
            {{ $t("Skip For Now") }}
          </button>
          <button
            @click="
              newUserDialog = false;
              this.$router.push('/setting/my-shop');
            "
            class="primary-blue"
          >
            {{ $t("Connect Your Store") }}
          </button>
        </div>
      </v-card>
    </v-dialog>

    <!-- renew dialog -->
    <renewDialog />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ListOfProduct from "@/components/dashboard-layout/ListOfProduct.vue";
import Navbar from "@/components/dashboard-layout/Navbar";
import renewDialog from "@/components/dashboard-layout/renewDialog";
import UserData from "@/components/dashboard-layout/UserData.vue";
import { getResponseAi, getMyShops } from "@/api/products";
import { profile } from "@/api/profile";
export default {
  name: "dashboardLayout",
  components: {
    Navbar,
    UserData,
    ListOfProduct,
    renewDialog,
  },
  data() {
    return {
      newUserDialog: false,
    };
  },
  unmounted() {
    // Clear the interval when the component is about to be destroyed
    this.clearInterval();
  },
  mounted() {
    this.getMyShops();

    let uniqueId = localStorage.getItem("unique_user_id");
    let user = JSON.parse(localStorage.getItem("listapro-user"));

    if (!uniqueId) {
      let uniqueId = "user_" + Math.random().toString(36).substring(2, 15);
      localStorage.setItem("unique_user_id", uniqueId);
    }

    if (user) {
      window.Echo.channel("requests-channel" + uniqueId).listen(
        ".request-updated",
        (e) => {
          getResponseAi(
            e.message.request_response.request_identifier,
            e.message.request_response.request_status
          ).then((response) => {
            let dataProducts = this.dataGenerated;
            this.$store.dispatch(
              "triggerHistoryRefresh",
              e.message.request_response.request_identifier
            );
            for (let i = 0; i < dataProducts.length; i++) {
              if (
                dataProducts[i].ai_token ==
                e.message.request_response.request_identifier
              ) {
                dataProducts[i] = response.result;
                this.setListOfProducts(dataProducts);
                this.setDataGenerated(dataProducts);
              }
            }
            this.getProfile();
          });
        }
      );

      // if the user is new and don't connect to channel
      if (this.newUser && !user.shops?.length) {
        this.newUserDialog = true;
      }
    } else {
      this.$store.dispatch("showError", this.$t("User is not defined"));
    }

    // to make scrollbar moving to top when open new page
    this.$router.afterEach(() => {
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  },
  computed: {
    ...mapGetters([
      "isListOfProductsDisabled",
      "listOfProducts",
      "dataGenerated",
    ]),
    ...mapState(["showCreditsWarning", "pointsRemain", "newUser"]),
  },
  methods: {
    ...mapMutations([
      "setListOfProducts",
      "setIsListOfProductsDisabled",
      "setDataGenerated",
      "setPointsRemain",
      "setShowCreditsWarning",
      "setProfileUploaded",
      "setHasChannels",
      "updateListaproUser",
      "setHasChannelsLoader",
    ]),

    // get My Shops
    getMyShops() {
      this.setHasChannelsLoader(true);
      getMyShops()
        .then((response) => {
          if (response.data?.length) {
            if (response.data[0].channel_status === "UNINSTALLED") {
              this.setHasChannels(false);
            } else {
              this.setHasChannels(true);
            }
          } else {
            this.setHasChannels(false);
          }
        })
        .finally(() => {
          this.setHasChannelsLoader(false);
        });
    },

    // close Warning
    closeWarning() {
      this.setShowCreditsWarning(false);
    },

    // get Profile
    getProfile() {
      this.setProfileUploaded(false);
      profile().then((response) => {
        this.setPointsRemain(response.points_data.remaining_points);
        this.setProfileUploaded(true);
        localStorage.setItem("listapro-user", JSON.stringify(response));
        this.updateListaproUser(JSON.stringify(response));
        // to show credits warning if there was no credits
        if (this.pointsRemain > 0) {
          this.setShowCreditsWarning(false);
        } else {
          this.setShowCreditsWarning(true);
        }
      });
    },

    // update Is List Of Products Disabled
    updateIsListOfProductsDisabled() {
      this.setIsListOfProductsDisabled(this.listOfProducts.length > 0);
    },

    // clear Interval
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },
  watch: {
    listOfProducts: {
      handler(newValue, oldValue) {
        this.updateIsListOfProductsDisabled();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-layout {
  height: 100vh;
  overflow: hidden;
  position: relative;
  .hide-in-small {
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  .content {
    height: calc(100vh - 64px);
    overflow: auto;
    background-color: #fafafa;
  }
  .list-of-product {
    position: fixed;
    bottom: 0;
    width: 37%;
    right: 1.2rem;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px #00230b33;
    z-index: 1000;
    @media (max-width: 576px) {
      right: 5%;
      width: 90%;
      bottom: 1%;
    }
  }
}
.credits-warning {
  width: calc(100% - 265px - 2rem);
  background-color: #fff1f0;
  border: 1px solid #fde0de;
  padding: 0.7rem 1rem;
  margin: 1rem 1rem 0 calc(265px + 1rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 6px;
  @media (max-width: 992px) {
    width: calc(100% - 2rem);
    margin: 1rem 1rem 0 1rem;
  }
  @media (max-width: 576px) {
    display: block;
    text-align: center;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 !important;
    @media (max-width: 576px) {
      display: block;
      &.btns {
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem !important;
      }
    }
  }
  .title {
    color: #000;
    font-size: 18px;
  }
  .desc {
    color: #5a5a5a;
    font-size: 14px;
  }
  button {
    height: 2.2rem;
    padding: 0.2rem 1rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    width: max-content;
    &.close {
      border: 1px solid #e53935;
    }
    &.pay-now {
      background-color: #fff;
      color: #2e7d32;
      border: 1px solid #2e7d32;
    }
  }
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  @media (max-width: 576px) {
    display: block;
  }
}

[dir="rtl"] .credits-warning {
  margin: 1rem calc(265px + 1rem) 0 1rem;
}
[dir="rtl"] .dashboard-layout .list-of-product {
  right: unset;
  left: 1.2rem;
}
</style>
