import { createStore } from "vuex";
import router from "@/router";

// *********************************************************************
// getInitialState
function getInitialState() {
  return {
    pointsRemain: null,
    filterHistory: "all",
    category: "",
    dataGenerated: [],
    listOfProducts: [],
    isListOfProductsDisabled: false,
    snackbarQueue: [],
    snackbarMessage: "",
    snackbarVisible: false,
    refreshHistory: false,
    listaproUser: localStorage.getItem("listapro-user") || "",
    managementWay: localStorage.getItem("managementWay") || "image",
    productsToEnhance: [],
    subscribeInfo: null,
    subscribeInfoLoader: true,
    haveBasicSubscribe: false,
    showCreditsWarning: false,
    profileUploaded: false,
    hasChannels: false,
    hasChannelsLoader: false,
    newUser: false,
    hideCardInfo: localStorage.getItem("HideCardInfo") || false,
    isSubscriptionFetched: false,
    renewDialog: false,
    renewTypeDialog: "expired",
  };
}
export default createStore({
  // ********************************* state ************************************
  state: getInitialState(),

  // ********************************** getters ***********************************
  getters: {
    category: (state) => state.category,
    dataGenerated: (state) => state.dataGenerated,
    productsToEnhance: (state) => state.productsToEnhance,
    listOfProducts: (state) => state.listOfProducts,
    isListOfProductsDisabled: (state) => state.isListOfProductsDisabled,
    snackbarMessage: (state) => state.snackbarMessage,
    snackbarVisible: (state) => state.snackbarVisible,
  },

  // ********************************** mutations ***********************************
  mutations: {
    // ---------------------------------
    // set Subscription Fetched
    setSubscriptionFetched(state, status) {
      state.isSubscriptionFetched = status;
    },

    // ---------------------------------
    // update Listapro User
    updateListaproUser(state, newValue) {
      state.listaproUser = newValue;
    },

    // ---------------------------------
    // change Management Way
    changeManagementWay(state, newValue) {
      state.managementWay = newValue;
    },

    // ---------------------------------
    // Set New User
    SetNewUser(state, newValue) {
      state.newUser = newValue;
    },

    // ---------------------------------
    // set Points Remain
    setPointsRemain(state, newValue) {
      state.pointsRemain = newValue;
    },

    // ---------------------------------
    // set Show Credits Warning
    setShowCreditsWarning(state, newValue) {
      state.showCreditsWarning = newValue;
    },

    // ---------------------------------
    // set Profile Uploaded
    setProfileUploaded(state, newValue) {
      state.profileUploaded = newValue;
    },

    // ---------------------------------
    // update Category
    updateCategory(state, newValue) {
      state.category = newValue;
    },

    // ---------------------------------
    // Set Hide Card Info
    SetHideCardInfo(state, newValue) {
      state.hideCardInfo = newValue;
    },

    // ---------------------------------
    // Set Renew Dialog
    SetRenewDialog(state, dialog, type) {
      state.renewDialog = dialog;
      state.renewTypeDialog = type;
    },

    // ---------------------------------
    // clear User
    clearUser(state) {
      // Save the current language preference
      const savedLanguage = localStorage.getItem("listapro-language");

      // Clear all data from localStorage
      localStorage.clear();

      // Restore the language preference
      if (savedLanguage) {
        localStorage.setItem("listapro-language", savedLanguage);
      }

      Object.assign(state, getInitialState());
      router.push("/login");
    },

    // ---------------------------------
    // set Data Generated
    setDataGenerated(state, data) {
      state.dataGenerated = data;
    },

    // ---------------------------------
    // set Subscribe Info
    setSubscribeInfo(state, data) {
      state.subscribeInfo = data;
    },

    // ---------------------------------
    // set Subscribe Info Loader
    setSubscribeInfoLoader(state, data) {
      state.subscribeInfoLoader = data;
    },

    // ---------------------------------
    // set Have Basic Subscribe
    setHaveBasicSubscribe(state, data) {
      state.haveBasicSubscribe = data;
    },

    // ---------------------------------
    // update Products To Enhance
    updateProductsToEnhance(state, products) {
      state.productsToEnhance = products;
    },

    // ---------------------------------
    // add Products To Enhance
    addProductsToEnhance(state, product) {
      state.productsToEnhance.push(product);
    },

    // ---------------------------------
    // remove Products To Enhance
    removeProductsToEnhance(state, productId) {
      state.productsToEnhance = state.productsToEnhance.filter(
        (product) => product.id !== productId
      );
    },

    // ---------------------------------
    // clear Products To Enhance
    clearProductsToEnhance(state) {
      state.productsToEnhance = [];
    },

    // ---------------------------------
    // set Filter History
    setFilterHistory(state, filter) {
      state.filterHistory = filter;
    },

    // ---------------------------------
    // set Has Channels
    setHasChannels(state, status) {
      state.hasChannels = status;
    },

    // ---------------------------------
    // set Has Channels Loader
    setHasChannelsLoader(state, status) {
      state.hasChannelsLoader = status;
    },

    // ---------------------------------
    // set List Of Products
    setListOfProducts(state, products) {
      state.listOfProducts = products;
    },

    // ---------------------------------
    // set Is List Of Products Disabled
    setIsListOfProductsDisabled(state, products) {
      state.isListOfProductsDisabled = products;
    },

    // ---------------------------------
    // ADD SNACKBAR
    ADD_SNACKBAR(state, message) {
      const id = Date.now();
      state.snackbarQueue.push({ id, message });
    },

    // ---------------------------------
    // REMOVE SNACKBAR
    REMOVE_SNACKBAR(state, id) {
      state.snackbarQueue = state.snackbarQueue.filter(
        (snackbar) => snackbar.id !== id
      );
    },

    // ---------------------------------
    // set Refresh History
    setRefreshHistory(state, value) {
      state.refreshHistory = value;
    },
  },

  // ********************************** actions ***********************************
  actions: {
    // ---------------------------------
    // filter History
    filterHistory({ commit }, filter) {
      commit("setFilterHistory", filter);
    },

    // ---------------------------------
    // show Error
    showError({ commit }, message) {
      const id = Date.now();
      commit("ADD_SNACKBAR", { id, message });
      setTimeout(() => {
        commit("REMOVE_SNACKBAR", id);
      }, 6000);
    },

    // ---------------------------------
    // hide Snackbar
    hideSnackbar({ commit }) {
      commit("HIDE_SNACKBAR");
    },

    // ---------------------------------
    // trigger History Refresh
    triggerHistoryRefresh({ commit }, payload) {
      commit("setRefreshHistory", payload);
    },
  },
});
