<template>
  <!-- dialog when the user Subscribe expired   -->
  <div class="dialog">
    <v-dialog v-model="this.$store.state.renewDialog">
      <v-card
        style="border-radius: 10px; padding: 20px 24px"
        class="dialog-expired"
      >
        <div class="d-flex align-center justify-between">
          <v-btn
            color="#F5222D"
            variant="tonal"
            @click="SetRenewDialog(false, 'expired')"
          >
            <svg
              width="20"
              fill="#F5222D"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </v-btn>
        </div>

        <!-- lock icon -->
        <div class="lock">
          <lockLargeIcon />
        </div>

        <!-- title -->
        <p class="title">
          {{
            this.$store.state.renewTypeDialog === "expired"
              ? $t("Oops! Subscription Required")
              : $t("Oops! Credit Points Needed")
          }}
        </p>

        <!-- desc -->
        <p class="desc">
          {{
            this.$store.state.renewTypeDialog === "expired"
              ? $t(
                  "Your subscription has ended, but you can unlock this feature right now!"
                )
              : $t(
                  "Your credit points are exhausted, but you can unlock this feature instantly!"
                )
          }}
        </p>

        <!-- card-blue -->
        <div class="card-blue">
          <svg
            class="star-bottom"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 25C11.8088 25 11.25 24.4412 11.25 23.75C11.25 18.2362 6.76375 13.75 1.25 13.75C0.55875 13.75 0 13.1912 0 12.5C0 11.8088 0.55875 11.25 1.25 11.25C6.76375 11.25 11.25 6.76375 11.25 1.25C11.25 0.55875 11.8088 0 12.5 0C13.1912 0 13.75 0.55875 13.75 1.25C13.75 6.76375 18.2362 11.25 23.75 11.25C24.4412 11.25 25 11.8088 25 12.5C25 13.1912 24.4412 13.75 23.75 13.75C18.2362 13.75 13.75 18.2362 13.75 23.75C13.75 24.4412 13.1912 25 12.5 25Z"
              fill="#FECA47"
            />
          </svg>
          <svg
            class="star-top"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C9.447 20 9 19.553 9 19C9 14.589 5.411 11 1 11C0.447 11 0 10.553 0 10C0 9.447 0.447 9 1 9C5.411 9 9 5.411 9 1C9 0.447 9.447 0 10 0C10.553 0 11 0.447 11 1C11 5.411 14.589 9 19 9C19.553 9 20 9.447 20 10C20 10.553 19.553 11 19 11C14.589 11 11 14.589 11 19C11 19.553 10.553 20 10 20Z"
              fill="#FECA47"
            />
          </svg>

          <p class="title">{{ $t("Exclusive Limited-Time Offer") }}</p>
          <p class="desc">
            {{ $t("Renew now and") }}
            <span class="bold">{{ $t("enjoy 30% off") }} </span>
            {{
              $t(
                "your first  month or earn bonus points to access more features."
              )
            }}
          </p>
        </div>

        <!-- Renew Now btn -->
        <template v-slot:actions>
          <button class="primary-blue w-100" @click="GoToSubscribe">
            {{ $t("Renew Now") }}
          </button>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import lockLargeIcon from "@/components/ui/svgs/lockLargeIcon.vue";
import { mapMutations } from "vuex";
export default {
  name: "renewDialog",
  computed: {},
  methods: {
    ...mapMutations(["SetRenewDialog"]),

    // Go To Subscribe
    GoToSubscribe() {
      this.SetRenewDialog(false, "expired");
      this.$router.push("/setting/subscription");
    },
  },
  components: { lockLargeIcon },
};
</script>
