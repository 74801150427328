<template>
  <div class="product">
    <!-- the image of product -->
    <div
      class="image"
      :class="product.status == 'PROCESSING' ? 'loading-animation' : ''"
    >
      <img v-if="url" :src="url" alt="" />

      <div class="barcode" v-else>
        <svg
          v-if="product.data_source.type === 'barcode'"
          width="50"
          height="50"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="mx-auto d-block"
        >
          <path
            d="M24 32C10.7 32 0 42.7 0 56L0 456c0 13.3 10.7 24 24 24l16 0c13.3 0 24-10.7 24-24L64 56c0-13.3-10.7-24-24-24L24 32zm88 0c-8.8 0-16 7.2-16 16l0 416c0 8.8 7.2 16 16 16s16-7.2 16-16l0-416c0-8.8-7.2-16-16-16zm72 0c-13.3 0-24 10.7-24 24l0 400c0 13.3 10.7 24 24 24l16 0c13.3 0 24-10.7 24-24l0-400c0-13.3-10.7-24-24-24l-16 0zm96 0c-13.3 0-24 10.7-24 24l0 400c0 13.3 10.7 24 24 24l16 0c13.3 0 24-10.7 24-24l0-400c0-13.3-10.7-24-24-24l-16 0zM448 56l0 400c0 13.3 10.7 24 24 24l16 0c13.3 0 24-10.7 24-24l0-400c0-13.3-10.7-24-24-24l-16 0c-13.3 0-24 10.7-24 24zm-64-8l0 416c0 8.8 7.2 16 16 16s16-7.2 16-16l0-416c0-8.8-7.2-16-16-16s-16 7.2-16 16z"
          />
        </svg>
        {{ product.data_source?.value }}
      </div>
    </div>

    <!-- details of products (name,desc,btns) -->
    <div
      class="details d-flex align-center text-center justify-center flex-column"
    >
      <!-- name -->
      <h5 v-if="name && name.general" class="bold-text">
        {{ getName(name.general.product_name.value) }}
      </h5>

      <!-- desc -->
      <p class="description" v-if="name && name.general.product_description">
        {{ getDescription(name.general?.product_description?.value) }}
      </p>

      <!-- Generation Failed -->
      <div v-if="product.status == 'FAILED'">
        <!-- text -->
        <div class="status d-flex gap-2 align-center p-3">
          <svg
            width="16"
            height="22"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 4C4.13438 4 1 7.13438 1 11C1 14.8656 4.13438 18 8 18C11.8656 18 15 14.8656 15 11C15 7.13438 11.8656 4 8 4ZM10.5844 13.6594L9.55313 13.6547L8 11.8031L6.44844 13.6531L5.41563 13.6578C5.34688 13.6578 5.29063 13.6031 5.29063 13.5328C5.29063 13.5031 5.30156 13.475 5.32031 13.4516L7.35313 11.0297L5.32031 8.60938C5.30143 8.58647 5.29096 8.5578 5.29063 8.52812C5.29063 8.45937 5.34688 8.40312 5.41563 8.40312L6.44844 8.40781L8 10.2594L9.55156 8.40938L10.5828 8.40469C10.6516 8.40469 10.7078 8.45937 10.7078 8.52969C10.7078 8.55937 10.6969 8.5875 10.6781 8.61094L8.64844 11.0312L10.6797 13.4531C10.6984 13.4766 10.7094 13.5047 10.7094 13.5344C10.7094 13.6031 10.6531 13.6594 10.5844 13.6594Z"
              fill="#FF4D4F"
            />
          </svg>
          <p class="capitalize">{{ $t("Generation Failed") }}</p>
        </div>

        <!-- Regenerate btn -->
        <div class="actions d-flex justify-center mt-2">
          <button class="blue-btn" @click="regenerate(id)">
            {{ $t("Regenerate") }}
            <svg
              v-if="loading"
              xmlns="http://www.w3.org/2000/svg"
              style="margin: auto; background: none; display: block"
              width="20px"
              height="20px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#000"
                stroke-width="10"
                r="35"
                stroke-dasharray="164.93361431346415 56.97787143782138"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
            </svg>
          </button>
        </div>
      </div>

      <!-- if the product PROCESSING -->
      <div v-if="product.status == 'PROCESSING'">
        <div
          class="status w-fit d-flex gap-2 align-center position-relative cursor-pointer"
        >
          <v-progress-circular
            class=""
            :size="22"
            :width="1"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <p>{{ $t("Your Product is Currently Being Processed") }}</p>
        </div>
      </div>

      <!-- if the product generated -->
      <div v-if="product.status != 'PROCESSING' && product.status != 'FAILED'">
        <!-- msg text -->
        <div class="status w-fit d-flex gap-2 align-center p-3 cursor-pointer">
          <svg
            width="30"
            height="30"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 4C4.13438 4 1 7.13438 1 11C1 14.8656 4.13438 18 8 18C11.8656 18 15 14.8656 15 11C15 7.13438 11.8656 4 8 4ZM11.0234 8.71406L7.73281 13.2766C7.68682 13.3408 7.62619 13.3931 7.55595 13.4291C7.48571 13.4652 7.40787 13.4841 7.32891 13.4841C7.24994 13.4841 7.17211 13.4652 7.10186 13.4291C7.03162 13.3931 6.97099 13.3408 6.925 13.2766L4.97656 10.5766C4.91719 10.4938 4.97656 10.3781 5.07812 10.3781H5.81094C5.97031 10.3781 6.12187 10.4547 6.21562 10.5859L7.32812 12.1297L9.78438 8.72344C9.87813 8.59375 10.0281 8.51562 10.1891 8.51562H10.9219C11.0234 8.51562 11.0828 8.63125 11.0234 8.71406Z"
              fill="#52C41A"
            />
          </svg>

          <p class="capitalize" style="font-size: 14px">
            {{ $t("Product successfully generated and ready for next steps!") }}
          </p>
        </div>

        <!-- btns  -->
        <div class="actions d-flex justify-center">
          <!-- edit -->
          <router-link
            :to="{
              name: 'Update Product Details',
              params: { id: product?.id },
            }"
          >
            <button class="blue-btn">
              {{ $t("Edit Product") }}
            </button>
          </router-link>
          <svg
            width="16"
            height="22"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0V22" stroke="black" stroke-opacity="0.06" />
          </svg>

          <!-- preview -->
          <router-link
            :to="{
              name: 'Product Preview',
              params: { id: product.id },
            }"
          >
            <button class="blue-btn">{{ $t("Preview Listing") }}</button>
          </router-link>
          <svg
            v-if="justOneProduct"
            width="16"
            height="22"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0V22" stroke="black" stroke-opacity="0.06" />
          </svg>

          <!-- Download -->
          <button
            class="blue-btn"
            v-if="justOneProduct"
            @click="export_csv(product.id)"
          >
            {{ $t("Download") }}
          </button>
          <svg
            v-if="justOneProduct"
            width="16"
            height="22"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0V22" stroke="black" stroke-opacity="0.06" />
          </svg>

          <!-- Publish -->
          <button
            v-if="justOneProduct"
            class="blue-btn"
            :disabled="!hasChannels"
            :class="!hasChannels ? 'disabled-btn' : ''"
            @click="listProduct"
          >
            {{ $t("Publish Product") }}
            <v-tooltip
              v-if="!hasChannels"
              activator="parent"
              location="start"
              content-class="error-tooltip"
            >
              <div>
                {{ $t("Connect a Sales Channel to Enable Product Listing.") }}
              </div>
            </v-tooltip>
          </button>
          <svg
            width="16"
            height="22"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0V22" stroke="black" stroke-opacity="0.06" />
          </svg>

          <!-- delete -->
          <button class="cancel-btn" @click="cancelProduct">
            {{ $t("Delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- is Regenerated product Dialog -->
  <v-dialog v-model="isRegeneratedDialog">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
    </template>
    <v-card>
      <div class="d-flex align-center justify-center">
        <div>
          <div class="dialog-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                fill="#52C41A"
              />
            </svg>
          </div>
          <div class="dialog-success-title">
            {{ $t("Successfully Regenerated") }}
          </div>
          <div class="dialog-success-description">
            {{ $t("Regenerate request have been sent successfully.") }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>

  <!-- msg : Do you want to remove this product from the list -->
  <v-dialog v-model="DeleteProductDialog">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
    </template>
    <v-card>
      <div class="d-flex align-center justify-between">
        <div class="d-flex align-center">
          <div class="d-flex align-center mr-2">
            <ExclamationMark color="#FF9800" />
          </div>
          <div class="dialog-title">
            {{ $t("Confirm Deletion") }}
          </div>
        </div>
        <v-btn
          color="#F5222D"
          variant="tonal"
          @click="DeleteProductDialog = false"
        >
          <svg
            width="20"
            fill="#F5222D"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
          </svg>
        </v-btn>
      </div>

      <div class="dialog-description mt-3">
        {{ $t("Do you want to remove this product from the list?") }}
      </div>
      <template v-slot:actions>
        <div class="buttons mt-3">
          <button class="cancel-dialog" @click="DeleteProductDialog = false">
            {{ $t("Cancel") }}
          </button>
          <button
            class="confirm-delete"
            @click="deleteProduct"
            :disabled="productDeleteLoading"
          >
            {{ $t("Confirm") }}
            <v-progress-circular
              v-if="productDeleteLoading"
              indeterminate
              color="#fff"
              :size="20"
              class="ms-2"
            />
          </button>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { regenerateProduct, ExportCsv } from "@/api/products";
import ExclamationMark from "@/components/ui/svgs/ExclamationMark.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "ProductComp",
  emits: ["onDeleteProduct"],
  data() {
    return {
      productName: "",
      loading: false,
      isRegeneratedDialog: false,
      DeleteProductDialog: false,
      productIdForDelete: null,
    };
  },
  // ****************************************************************
  // ******************************** computed ********************************
  computed: {
    ...mapState(["hasChannels"]),
  },
  // ****************************************************************
  // ******************************** components ********************************
  components: { ExclamationMark },
  // ****************************************************************
  // ********************************** props ******************************
  props: [
    "id",
    "name",
    "url",
    "product",
    "justOneProduct",
    "productDeleteLoading",
  ],
  // ****************************************************************
  // ********************************** methods ******************************
  methods: {
    ...mapMutations(["setListOfProducts", "setDataGenerated"]),

    // export_csv
    export_csv(id) {
      // export it!
      const formData = new FormData();
      formData.append(`idArray`, JSON.stringify([id]));
      ExportCsv(formData).then((response) => {
        window.open(response.data.file_url, "_blank"); // Open the URL in a new tab
      });
    },

    // get Name
    getName(name) {
      if (name) {
        const truncatedName = name.slice(0, 50);
        const finalName =
          name.length > 50 ? truncatedName + "..." : truncatedName;
        return finalName;
      }
    },

    // get Description
    getDescription(description) {
      if (description) {
        const truncatedDescription = description.slice(0, 50);
        const finalDescription =
          description.length > 50
            ? truncatedDescription + "..."
            : truncatedDescription;
        return finalDescription;
      }
    },

    // cancel Product
    cancelProduct(id) {
      this.DeleteProductDialog = true;
      this.productIdForDelete = id;
    },

    // delete Product
    deleteProduct() {
      this.$emit("onDeleteProduct", this.productIdForDelete);
    },

    // regenerate
    regenerate(id) {
      this.loading = true;
      const unique_user_id = localStorage.getItem("unique_user_id");
      regenerateProduct(id, unique_user_id)
        .then((response) => {
          this.loading = false;
          if (response.message) {
            this.isRegeneratedDialog = true;
          }
          let dataProducts = this.$store.state.dataGenerated;
          for (let i = 0; i < dataProducts.length; i++) {
            if (dataProducts[i].id == id) {
              dataProducts[i] = response.result;
              this.setListOfProducts(dataProducts);
              this.setDataGenerated(dataProducts);
              this.loading = false;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // list Product
    listProduct() {
      this.$router.push({ path: "/wizard/listing" });
    },
  },

  // ****************************************************************
  // ******************************* mounted *********************************
  mounted() {
    if (this.name) {
      this.productName = this.name.general?.product_name?.value;
    }
  },
};
</script>

<style scoped lang="scss">
// product
.product {
  box-shadow: 0px 2px 0px 0px #0591ff1a;
  border: 1px solid #0000000f;
  border-radius: $main-border-radius;
  padding: 1.5rem;
  .barcode {
    padding: 0.5rem;
    background: #fafafa;
    font-size: 12px;
    border-radius: 0.5rem;
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      margin-bottom: 1rem;
    }
  }
  .details {
    h5 {
      font-size: 14px;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      color: #00000073;
      margin-top: 4px;
      margin-bottom: 0.5rem;
    }
    .status {
      box-shadow: 0px 2px 5px 1px #bfbfbf4a;
      border-radius: 8px;
      padding: 9px 7px;
      margin-top: 8px;
      svg {
        width: 35px;
      }
    }
    .actions {
      margin-top: 1rem;
      align-items: center;
      @media (max-width: 576px) {
        flex-wrap: wrap;
        gap: 1%;
        row-gap: 0.5rem;
        svg {
          display: none;
        }
      }
      & > a {
        @media (max-width: 576px) {
          width: 48%;
        }
      }
      & > button {
        background: transparent;
        @media (max-width: 576px) {
          width: 48%;
          margin: 0;
        }
        &:disabled {
          opacity: 0.7;
          background: transparent !important;
          border: none !important;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .product > div {
    flex-direction: column;
    .details {
      h5,
      .description {
        display: none;
      }
    }
  }
}

// -------------------------------------
// loading-animation
.loading-animation {
  position: relative;
  overflow: hidden;
}
.loading-animation::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 55%;
  background: linear-gradient(to right, transparent, #ffffff8f, transparent);
  transform: skewX(-20deg);
  animation: loading 1.5s infinite;
}
@keyframes loading {
  0% {
    transform: translateX(-100%) skewX(-20deg);
  }
  100% {
    transform: translateX(200%) skewX(-20deg);
  }
}
</style>
