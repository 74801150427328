<template>
  <svg
    width="102"
    height="102"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.1299 45.039L26.1742 38.6312C26.1738 36.349 26.1051 34.0262 26.3477 31.7548C26.9548 26.0722 29.6182 20.8928 33.6618 16.8929C38.4687 12.1378 44.7443 9.68262 51.484 9.72015C54.7245 9.73814 57.929 10.4001 60.9113 11.6676C63.8936 12.9351 66.5942 14.7828 68.8561 17.1033C73.5749 21.8793 75.928 27.8653 76.1435 34.5268C76.2246 37.0215 76.1598 39.5362 76.1568 42.0322C76.1556 43.0407 76.1284 44.0541 76.1816 45.0608C75.3353 45.2055 73.8564 45.0995 72.9308 45.1014L69.1019 45.1008C68.4172 45.0983 67.7095 45.131 67.0279 45.0681C65.1046 45.1074 63.1662 45.0608 61.2412 45.0626L48.0937 45.0596L39.5005 45.0614C38.1113 45.0638 36.7011 45.1304 35.314 45.0729C34.5587 45.1752 26.6605 45.168 26.1299 45.039ZM35.314 45.0729C36.7011 45.1304 38.1113 45.0638 39.5005 45.0614L48.0937 45.0596L61.2412 45.0626C63.1662 45.0608 65.1046 45.1074 67.0279 45.0681C66.908 42.3227 67.0158 39.5229 67.02 36.7727C67.0218 35.5432 67.0581 34.2974 66.9395 33.0727C66.5563 29.1221 64.3649 25.1817 61.3104 22.6699C57.9016 19.8667 53.7233 18.641 49.3386 19.0845C45.2573 19.4973 41.334 21.7708 38.7727 24.9522C34.9379 29.7153 35.2511 33.8082 35.2511 39.5084C35.2511 41.2615 35.1275 43.3767 35.314 45.0729Z"
      fill="#2C4B64"
    />
    <path
      d="M26.1304 45.0386C26.6611 45.1675 34.5592 45.1748 35.3146 45.0725C36.7016 45.13 38.1118 45.0634 39.501 45.061L48.0942 45.0592L61.2417 45.0622C63.1667 45.0604 65.1051 45.107 67.0284 45.0676C67.71 45.1306 68.4177 45.0979 69.1024 45.1003L72.9313 45.1009C73.8569 45.0991 75.3358 45.2051 76.1822 45.0604C76.6671 45.1118 77.1671 45.0852 77.655 45.1251C78.8996 45.2287 80.1055 45.6942 81.0311 46.5417C82.205 47.6156 82.866 49.0855 82.9368 50.6655C83.0567 53.3406 82.9429 56.2518 82.9405 58.9487L82.9502 74.244L82.9508 82.5296C82.9544 83.9183 83.0882 85.5819 82.8969 86.9458C82.7371 88.0845 82.2213 89.1172 81.441 89.9593C80.3441 91.1422 78.8851 91.7597 77.2869 91.8117C75.9963 91.8541 74.6911 91.8117 73.3993 91.8117L65.7281 91.8093L43.1409 91.8184L30.6125 91.8299L27.0331 91.8396C26.2062 91.8432 25.3635 91.8795 24.5414 91.7778C23.2768 91.6204 22.0297 91.0895 21.1032 90.2051C19.8893 89.0458 19.4374 87.5953 19.3946 85.9512C19.325 83.2773 19.3934 80.5858 19.3944 77.9095L19.396 62.2801L19.3913 54.5314C19.3888 53.053 19.2737 51.4652 19.4894 50.0044C19.66 48.8244 20.1979 47.728 21.0269 46.871C22.5166 45.3134 24.0701 45.0846 26.1304 45.0386Z"
      fill="#FF7875"
    />
    <path
      d="M50.8089 55.1007C51.3674 55.0402 51.9818 55.1062 52.5316 55.2115C54.482 55.5848 56.2066 56.7117 57.3318 58.3479C58.4162 59.9491 58.8765 62.0516 58.4966 63.9518C58.0415 66.228 56.6044 67.8516 54.7229 69.115C55.1137 71.6539 55.6088 74.1819 56.075 76.7081C56.257 77.6943 56.6654 79.2234 56.6486 80.1624C56.6411 80.5849 56.3782 81.0032 56.0776 81.2847C55.3377 81.9785 52.4491 81.7175 51.4241 81.7121C50.3164 81.7145 48.1421 81.8556 47.1336 81.6764C46.6607 81.5928 46.2322 81.3646 45.9652 80.9536C45.8034 80.7042 45.7317 80.4118 45.736 80.1164C45.7519 79.0218 46.1267 77.8408 46.3245 76.7584C46.7874 74.2231 47.1948 71.6636 47.7888 69.1556C47.2806 68.7288 46.7228 68.3583 46.2095 67.9369C44.7687 66.7522 43.9047 65.0154 43.7392 63.1624C43.5821 61.2271 44.1904 59.3075 45.4333 57.8158C46.8297 56.1698 48.6739 55.2866 50.8089 55.1007Z"
      fill="#2C4B64"
    />
  </svg>
</template>
